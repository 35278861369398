@tailwind base;

@layer base {}

@tailwind components;

@layer components {}

@tailwind utilities;

@font-face {
  font-family: 'Moret';
  font-weight: 700;
  src: local('Moret'), url('../assets/fonts/Moret-Semibold.woff') format('woff');
}

@layer utilities {
  .full-size {
    @apply w-full h-full;
  }

  .absolute-size {
    @apply absolute w-full h-full inset-0;
  }

  .fixed-size {
    @apply fixed w-full h-full inset-0;
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2;
  }

  .absolute-x-center {
    @apply absolute left-1/2 transform -translate-x-1/2;
  }

  .absolute-y-center {
    @apply absolute top-1/2 transform -translate-y-1/2;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-col-center {
    @apply flex-center flex-col;
  }

  .icon-sm {
    @apply min-w-4 w-4 h-4;
  }

  .icon-md {
    @apply min-w-6 w-6 h-6;
  }

  .icon-xl {
    @apply min-w-8 w-8 h-8;
  }

  .gradient-green {
    @apply bg-gradient-to-b from-green-200 to-green-400;
  }

  .gradient-sky-to-cream {
    @apply bg-gradient-to-b from-sky-100 to-cream-100;
  }

  .ring-gray {
    @apply ring-2 ring-gray-700 ring-offset-2 ring-offset-gray-700;
  }

  .ring-indigo {
    @apply ring-2 ring-indigo-500 ring-offset-2 ring-offset-indigo-700;
  }

  .ring-emerald {
    @apply ring-2 ring-emerald-500 ring-offset-2 ring-offset-emerald-700;
  }

  .ring-red {
    @apply ring-2 ring-red-500 ring-offset-2 ring-offset-red-700;
  }
}