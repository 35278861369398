@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.noAutocomplete {
  color: rgb(74, 74, 74);
  padding: 8px;
  position: absolute;
  top: 50px;
  z-index: 999;
  background-color: white;
}

.inputWrapper {
  position: relative;
}

.inputError {
  border: var(--borderErrorField);
}

.autocomplete {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 180px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;
  top: 50px;
  z-index: 999;
  background-color: white;
}

.autocomplete li {
  padding: 8px;
}

.autocomplete>.active,
.autocomplete li:hover {
  background-color: darkgray;
  cursor: pointer;
  font-weight: 700;
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}